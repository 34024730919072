<template>
  <div class='home'>
  <v-container>
      <v-btn
       @click='Signout'
       text
       class="logoutbtn"
       >&lt;Logout</v-btn>
      <v-card-title>
        Liquitous account
        <v-spacer></v-spacer>
        <v-text-field
          v-model='search'
          outlined
          append-icon='mdi-magnify'
          label='Search'
          single-line
          hide-details
        ></v-text-field>
        <v-row>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog1"
            persistent
            max-width="600px"
          >
          <v-spacer></v-spacer>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color='blue right ml-4'
                link
                dark
                v-bind="attrs"
                v-on="on"
              >＋ユーザーの追加</v-chip>
            </template>
            <v-card
              max-width="1200"
            >
              <v-card-title>
                <span class="headline font-weight-bold">アカウントを追加</span>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  color="blue"
                  class="white--text"
                  elevation="0"
                >
                  アカウント作成
                </v-btn>
                <template v-slot:activator="{ on, attrs }">
                <v-chip
                  color='blue right ml-4'
                  link
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >アカウント作成</v-chip>
                </template>
              </v-card-title>

              <!--登録内容の確認-->
                <v-dialog v-model="regConfirm" width="60%">
                <v-card>
                  <p class="text-center pt-14 font-weight-bold px-8 py-4 text-h5">登録内容の確認</p>
                  <v-card-actions>
                  </v-card-actions>
                </v-card>
                </v-dialog>
              <v-col>
              <v-row>
                <v-spacer></v-spacer>
              <span class="font-weight-bold">Family Name</span>
                <v-spacer></v-spacer><v-spacer></v-spacer>
              <span class="font-weight-bold">Sur Name</span>
                <v-spacer></v-spacer><v-spacer></v-spacer>
              <span class="font-weight-bold">E-mail Address</span>
                <v-spacer></v-spacer>
              </v-row>
              </v-col>
              <v-container
                id="scroll-target"
                style="max-height: 370px"
                class="overflow-y-auto"
              >
              <v-row
                v-scroll:#scroll-target="onScroll"
                align="start"
                justify="center"
              >
              <v-card-text>
                <v-container>
                  <v-row v-for="n in 10" :key="n">
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model='fn[n]'
                        required
                        outlined
                        rounded
                        hide-details
                        background-color="#DCDCDC"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model='sn[n]'
                        required
                        outlined
                        rounded
                        hide-details
                        background-color="#DCDCDC"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model='em[n]'
                        required
                        outlined
                        rounded
                        hide-details
                        background-color="#DCDCDC"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog1 = false"
                >
                  CANCEL
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-title>
      <table class="table">
        <thead class="thead">
          <tr>
          <th class='user_index'>State</th>
          <th class='user_uid'>UserID</th>
          <th class='user_name'>Name</th>
          <th class='user_email'>E-mail Address</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(eachuser, index) in userlist" :key='eachuser.uid'>
          <td class='user_index'>{{index}}</td>
          <td class='user_uid'>{{eachuser.uid}}</td>
          <td class='user_name'>{{eachuser.displayName}}</td>
          <td class='user_email'>{{eachuser.email}}</td>
          <td><v-btn elevation="2"><router-link :to="{name: 'Account', params: {id: index}}">編集({{index}})</router-link></v-btn></td>
          </tr>
        </tbody>
      </table>
        <v-divider></v-divider>
      <v-btn
        elevation="2"
        v-on:click="start()"
      >読込</v-btn>
      <!--
      <v-btn
        elevation="2"
        v-on:click="edit(0)"
      >ポスト</v-btn>
      -->
      <v-btn
        elevation="2"
        v-on:click="relist()"
      >再取得</v-btn>
      <p>{{userlist}}</p>
  </v-container>
  </div>
</template>

<script>
import firebase from 'firebase'

// import { delete } from 'vue/types/umd'
export default {
  data () {
    return {
      i: 0,
      k: 0,
      userlist: [],
      search: '',
      dialog1: false,
      dialog2: false,
      fn: [],
      sn: [],
      nm: [],
      em: [],
      notifications: false,
      sound: true,
      widgets: false,
      regConfirm: false
    }
  },
  methods: {
    getColor (disabled, isDeleted) {
      if (isDeleted === true) return 'grey'
      if (disabled === true) return 'red'
      else return 'green'
    },
    Signout: function () {
      firebase.auth().signOut().then(() => {
        this.$router.push('/signin')
      })
    },
    onScroll (e) {
      this.offsetTop = e.target.scrollTop
    },
    // サーバーがあらかじめ採った情報を読み込む
    start () {
      this.$axios
        .get('http://localhost:3000/box/userlist.json')
        .then(
          response => {
            this.userlist = []
            this.userlist = response.data
            console.log('読込:' + this.userlist + 'a')
          }
        )
    },
    list () {
      this.$axios
        .get('http://localhost:3000/maron/list')
        .then(
          response => {
            // this.userlist = [];
            // this.userlist = response.data
            console.log('取得')
          }
        )
    },
    restart () {
      setTimeout(this.start, 5000)
    },
    // サーバーから情報を再取得
    relist () {
      this.$axios
        .get('http://localhost:3000/maron/relist')
        .then(
          response => {
            console.log('再取得:' + response.data)
            this.restart()
          }
        )
    },
    /*
    relist () {
      this.$axios
        .get('http://localhost:3000/maron/relist')
        .then(
          response => {
            console.log('再取得:' + response.data)
            this.restart()
          }
        )
    },
    */
    /*
    edit (index) {
      this.$axios.post('http://localhost:3000/maron', { uid: this.userlist[index].uid })
        .then(res => {
          console.log('yah' + JSON.stringify(res.uid) + index)
        })
        .catch(error => {
          console.log(error)
        })
    },
    */
    del (index) {
      this.$axios.post('http://localhost:3000/maron/delete', { uid: this.userlist[index].uid })
        .then(res => {
          console.log('yah' + JSON.stringify(res.uid) + index)
          this.start()
        })
        .catch(error => {
          console.log(error)
        })
    },
    up (index) {
      this.$axios.post('http://localhost:3000/maron/up', { uid: this.userlist[index].uid })
        .then(res => {
          console.log('yah' + JSON.stringify(res.uid) + index)
          this.start()
        })
        .catch(error => {
          console.log(error)
        })
    },
    submit () {
      console.log(this.fn, this.sn, this.em)
      this.nm = this.fn + this.sn
      this.$axios.post('http://localhost:3000/maron/cre',
        {
          nm: this.nm,
          email: this.em
        })
        .then(res => {
          console.log('SubmitSuccessfully:' + JSON.stringify(res))
        })
        .catch(error => {
          console.log(error)
        })
      this.dialog1 = false
    }
  },
  created () {
    this.start()
  }
}
</script>

<style scoped>
.table{
  margin:10px;
}
.user_uid{
  width:100rem;
  padding-left:5px;
}
.user_name{
  width:50rem;
}
.user_email{
  padding-left:5px;
  width:150rem;
}
.logoutbtn{
  color: #e64550;
  font-size: 20px;
}
*{
  font-family:mplus-2c-medium;
}
</style>
