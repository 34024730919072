<template>
  <v-container>
    <v-btn
      text
      class="return font-weight-bold"
      href="/"
    >&lt;戻る</v-btn>
    <v-row justify="center">
      <h1>NO.{{ this.$route.params.id }}</h1>
    </v-row>
    <v-row justify="center">
        <v-col align="right"><img src="1001.jpg" alt="アイコン" width="100%"></v-col>
        <v-col align="center" class="displayName"><h1>{{this.list[this.$route.params.id].displayName}}</h1></v-col>
        <v-col fluid class="px-4" align="left">
          <v-switch
            v-model="sw"
            color="success"
            :label="`active: ${sw.toString()}`"
          ></v-switch>
        </v-col>
    </v-row>
    <v-row>
    <v-col align="center" class="UserName font-weight-bold">{{ radios || 'Normal User' }}
    <a
      class="change font-weight-bold"
      align="right"
      @click="typechange=true"
      >
      >変更
    </a>
    </v-col>
    </v-row>
    <v-row justify="center">
      <table>
        <tr>
          <th align="right">E-mail Address:</th><td>{{this.list[this.$route.params.id].email}}</td><td class="confirmed" v-if="this.list[this.$route.params.id].emailVerified === true">Confirmed</td>
        </tr>
        <tr>
          <th align="right">Phone Number:</th><td>{{this.list[this.$route.params.id].phonenumber}}</td>
        </tr>
        <tr>
          <th align="right">Password:</th><td>非対応</td>
          <a
            class="change font-weight-bold"
            align="right"
            @click="reSetting=true"
          >>再設定</a>
        </tr>
        <tr>
          <th align="right">Created time:</th><td>{{this.list[this.$route.params.id].metadata.creationTime}}</td>
        </tr>
        <tr>
          <th align="right">User ID:</th><td>{{this.list[this.$route.params.id].uid}}</td>
        </tr>
      </table>
    </v-row>
    <v-row justify="center">
      <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          color="red lighten-1"
          class="mt-16 px-6 text-h6"
          dark
          large
          v-bind="attrs"
          v-on="on"
        >×Delete User</v-chip>
      </template>

      <v-card class="text-center">
        <v-card-text class="pt-8 headline lighten-2">
          本当にこのアカウントを削除しますか？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            text
            @click="dialog = false"
          >
            いいえ
          </v-btn>
          <v-btn
            color="red"
            text
            @click="del"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-row>
    <v-dialog v-model="typechange" width="30%">
    <v-card
      height="120%">
      <v-col class="font-weight-bold">ユーザーの種類</v-col>
    <v-container
        fluid
        class="pl-12 pb-12"
    >
      <v-radio-group
        v-model="radios"
        mandatory
      >
        <v-radio
          class="orange-label orange--text"
          color="orange"
          value="Admin User"
          hide-details
          :label="`Admin User`"
        >
        <template v-slot:label>
          <div><strong class="warning--text">Admin User</strong></div>
        </template>
        </v-radio>
        <v-radio
          color="black"
          hide-details
          value="Normal User"
          :label="`Normal User`"
        >
        <template v-slot:label>
          <div><strong class="black--text">Normal User</strong></div>
        </template>
        </v-radio>
      </v-radio-group>
      </v-container>
      <v-col>
      <v-btn
        class="save white--text"
        elevation="0"
        rounded
        color="blue"
        @click="typechange=false"
      >保存
      </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="reSetting" width="800px">
    <v-card height="120%">
      <v-col>
      <p class="title1 font-weight-bold pt-6" align="center">
        登録メールアドレスに
      </p>
      <p class="title1 text-h6 font-weight-bold pb-4" align="center">
        パスワードリセットリンクを送付しました
      </p>
      </v-col>
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default ({
  data () {
    return {
      id: this.$route.params.id,
      list: [],
      confirmed: false,
      sw: true,
      dialog: false,
      radios: 'Normal User',
      typechange: false,
      reSetting: false
    }
  },
  methods: {
    del () {
      this.$axios.post('http://localhost:3000/maron/delete', { uid: this.list[this.$route.params.id].uid })
        .then(res => {
          console.log('Delete:' + JSON.stringify(res.uid) + this.list[this.$route.params.id].uid)
          this.$router.push('/')
          this.dialog = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created: function () {
    axios.get('http://localhost:3000/box/userlist.json').then(function (response) {
      // 取得完了したらlistリストに代入
      this.list = response.data
      console.log('やった' + JSON.stringify(this.list))
    }.bind(this)).catch(function (e) {
      console.error(e)
    })
    this.sw = this.list[this.$route.params.id].disabled
    console.log('ああああああああああああああああああああああ' + this.sw)
  }
})
</script>

<style scoped>
.confirmed{
  color: #54E363;
}
.displayName{
  max-width: 500px;
}
.return{
  font-size: 20px;
  position:absolute;
  left:10%;
  top:5%;
  color:#008cff;
}
.UserName{
  color:#707070;
  font-size: 24px;
}
.change{
  font-size: 15px;
}
.save{
  position: absolute;
  right:7%;
  top:75%;
}
.title1{
  font-size: 20px;
}
  *{
    font-family:mplus-2c-medium;
  }

</style>
