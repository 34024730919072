<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
      flat
    >
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="./assets/svg/1111.svg"
          transition="scale-transition"
          width=""
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://liq-account.web.app"
        target="_blank"
        text
      >
        <span style="color:#008CFF" class="mr-2">Liqlid</span>
        <v-icon style="color:#008CFF">mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    //
  })
}
</script>
