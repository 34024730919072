import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase'
import axios from 'axios'
import './assets/sass/style.scss'

Vue.config.productionTip = false
Vue.prototype.$axios = axios

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: 'AIzaSyABLLZo1FSPRSGIZfqkQBugCIWUBfDUpqY',
  authDomain: 'liq-account.firebaseapp.com',
  projectId: 'liq-account',
  storageBucket: 'liq-account.appspot.com',
  messagingSenderId: '745860980985',
  appId: '1:745860980985:web:df9638063cc0cf2ce90228'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

new Vue({
  router,
  store,
  axios,
  vuetify,
  render: h => h(App)
}).$mount('#app')
