import Vue from 'vue'
import VueRouter from 'vue-router'
import welcome from '../views/welcome.vue'
import Home from '../views/Home.vue'
import firebase from 'firebase'
import account from '../views/Account.vue'
import passwordreset from '../views/passwordreset.vue'
import passwordresetsend from '../views/passwordresetsend.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: welcome
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/passwordreset',
    name: 'passwordreset',
    component: passwordreset
  },
  {
    path: '/passwordresetsend',
    name: 'passwordresetsend',
    component: passwordresetsend
  },
  {
    path: '/Signin',
    name: 'Signin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Signin.vue')
  },
  {
    path: '/account/:id',
    name: 'Account',
    component: account,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '*',
    name: 'all',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(recode => recode.meta.requireAuth)
  if (requireAuth) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        next()
      } else {
        next('/signin')
      }
    })
  } else {
    next()
  }
})

export default router
