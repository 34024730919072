<template>
<v-main>
    <transition appear>
      <p class="text-h4 text-center welcome" v-if="welif">Welcome to Liquitous Account</p>
    </transition>
</v-main>

</template>

<script>
export default {
  data () {
    return {
      welif: true
    }
  },
  methods: {
    timeout: function () {
      setTimeout(() => {
        this.$router.push('/home')
      }, 2000)
    },
    wel: function () {
      setTimeout(() => {
        this.welif = false
      }, 1000)
    }
  },
  mounted () {
    this.wel()
    this.timeout()
  }
}
</script>

<style scoped>
.v-enter-active, .v-leave-active {
  transition: opacity 1s;
}

.v-enter{
  opacity: 0;
}
.v-enter-to{
    opacity: 1;
}
.v-leave {
    opacity: 1;
}
.v-leave-to {
    opacity: 0;
}
.welcome{
    margin-top: 10%;
}
*{
  font-family:mplus-2c-medium;
}
</style>
