<template>
  <v-row>
    <v-col>
      <v-img
        src="../assets/LiqAdminLogin.png"
        width="400%"
        height="100%"
      ></v-img>
    </v-col>
    <v-col>
      <h2 style="font-family:mplus-2c-medium;" class="text-left my-10 blue--text text--lighten-3 ml-16 mt-8">
        Log in
      </h2>
      <v-form v-model="valid" class="my-10 text-center">
        <v-container fluid fill-height class="text-center">
          <v-row>
            <v-col
              v-for="n in 1" :key="n"
              cols=12 sm=10
              class="mx-auto"
            >
              <v-text-field
                prepend-inner-icon="mdi-email-outline"
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                auto-grow
                outlined
                rounded
                rows="1"
                row-height="15"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="n in 1" :key="n"
              cols=12 sm=10
              class="mx-auto"
            >
              <v-text-field
                prepend-inner-icon="mdi-lock"
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
                name="input-10-1"
                label="Password"
                counter
                rounded
                @click:append="show1 = !show1"
                auto-grow
                outlined
                rows="1"
                row-height="15"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-0">
              <v-checkbox
                v-model="checkbox"
                style="font-family:mplus-2c-medium;"
                label="次回以降、自動でログインする"
                required
                class="ml-16 my-0"
              ></v-checkbox>
          </v-row>
          <v-row class="text-right">
            <v-col>
              <a href="../passwordreset" class="font-weight-bold text-right mr-16" style="color:#008CFF;"> パスワードを忘れましたか？</a>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col>
              <v-btn
                large
                width="10px"
                @click="login()"
                style="text-transform: none; font-size:18px;"
                class="mb-4 px-16 white--text"
                color="#008CFF"
                :disabled="password.length <= 7 || !email"
                rounded
              >
                Log in
              </v-btn>
            </v-col>
          </v-row>
       </v-container>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import firebase from 'firebase'

export default {
  data: () => ({
    show1: false,
    password: '',
    rules: {
      required: value => !!value || 'パスワードを入力してください.',
      min: v => v.length >= 8 || '8文字以上入力してください',
      emailMatch: () => ('入力したメールアドレスまたはパスワードが正しくありません')
    },
    email: '',
    emailRules: [
      v => !!v || 'メールアドレスを入力してください',
      v => /.+@.+/.test(v) || 'メールアドレスの形式が違います。\nもう一度正しく入力してください'
    ],
    checkbox: false
  }),
  methods: {
    signin: function () {
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
        user => {
          this.$router.push('/')
        },
        err => {
          alert(err.message)
        }
      )
    },
    login: function () {
      if (this.checkbox === true) {
        this.setperloc()
      }
      if (this.checkbox === false) {
        this.setpersec()
      }
    },
    setpersec: function () {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return this.signin()
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message
          console.log(errorCode, errorMessage)
        })
    },
    setperloc: function () {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return this.signin()
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message
          console.log(errorCode, errorMessage)
        })
    }
  }
}
</script>

<style scoped>
  .v-chip{
    width:90px;
    font-size: 25px;
  }

  *{
    font-family:mplus-2c-medium;
  }
</style>
